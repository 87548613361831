import { ESGlossary } from '../../elastic_search'
import { Glossary } from '../../firebase'
import { Designed } from '../../types'
import { MathJax } from '../mathdown/mathjax'
import { ShapedTimestamp } from '../timestamp/ShapedTimestamp'
import { GlossaryTitleLink } from './TitleLink'

type ItemProps = {
  glossary: Glossary | ESGlossary
}

const GlossaryItem = ({ glossary, ...otherProps }: Designed<ItemProps>) => {
  return (
    <div {...otherProps}>
      <h3 className="my-0 fs-5">
        <GlossaryTitleLink glossary={glossary} />
      </h3>
      <MathJax className="my-2 text-truncate-3">{glossary.summary}</MathJax>
      <div className="text-muted small">
        <ShapedTimestamp timestamp={glossary.created_at} />
      </div>
    </div>
  )
}

type Props = {
  glossaries: Glossary[] | ESGlossary[]
}

export const GlossaryList = ({
  glossaries,
  ...otherProps
}: Designed<Props>) => {
  return (
    <div {...otherProps}>
      <div className="d-flex flex-column gap-6">
        {glossaries.map((g) => (
          <GlossaryItem key={g.id} glossary={g} />
        ))}
        {glossaries.length === 0 && <>用語の解説がありません。</>}
      </div>
    </div>
  )
}
