import { MutableRefObject } from 'react'
import { Badge } from 'react-bootstrap'
import { Mathdown } from '../../assets/mathdown'
import { MathJax } from '../../assets/mathdown/mathjax/components'
import { ReferencesList } from '../../assets/reference/List'
import { Category, Glossary, Macro, Reference } from '../../firebase'
import { Designed } from '../../types'
import { GlossaryActionLinks } from './ActionLinks'

type Props = {
  contentRef: MutableRefObject<HTMLDivElement | null>
  category?: Category
  glossary: Glossary
  references: Reference[]
  macros: Macro[]
}

export const GlossaryDetail = ({
  contentRef,
  category,
  glossary,
  macros,
  references,
  ...containerProps
}: Designed<Props>) => {
  const convertibleTerms = glossary.terms.slice(1)
  return (
    <div {...containerProps}>
      <div className="mathdown-monotone">
        <h1>{glossary.terms[0]?.display ?? '用語未設定'}</h1>
        {convertibleTerms.length > 0 && (
          <div>
            同義語：
            {convertibleTerms.map((t, index) => (
              <Badge className="bg-secondary me-1" key={index}>
                {t.display}
              </Badge>
            ))}
          </div>
        )}
        <GlossaryActionLinks glossary={glossary} className="mt-1" />
        <div ref={contentRef}>
          <h2>概要</h2>
          <MathJax>
            <p>{glossary.summary}</p>
          </MathJax>
          <Mathdown
            mathdown={glossary.detail}
            macros={macros}
            references={references}
            className="text-break"
          />
          {references.length > 0 && (
            <>
              <h2>参考文献</h2>
              <ReferencesList references={references} amazonBookImage link />
            </>
          )}
        </div>
      </div>
    </div>
  )
}
