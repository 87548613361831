import { limit, orderBy, query } from 'firebase/firestore'
import {
  Batch,
  DocRef,
  Glossary,
  GlossaryDraft,
  Term,
  Timestamp,
  getDoc,
  glossariesRef,
  glossaryDraftsRef,
  glossaryRef,
  termsWithGlossaryInfoRef,
} from '../firebase'
import { addDefaultMacos, addMacros, replaceMacros } from './macros'
import { addReferences, replaceReferences } from './references'

export const recentGlossariesRef = (size: number) =>
  query(glossariesRef, orderBy('created_at', 'desc'), limit(size))

export const createGlossaryDraft = async (
  uid: string,
  publicationRef?: DocRef<Glossary>
) => {
  const batch = new Batch()
  const now = Timestamp.now()
  if (publicationRef) {
    const publicationSnapshot = await getDoc(publicationRef)
    const publication = publicationSnapshot.data()
    if (!publication) throw new Error('The publication is not found.')
    const ref = batch.add(glossaryDraftsRef, {
      ...publication,
      publication_id: publication.id,
      created_by_user_at: now,
      updated_by_user_at: now,
    })
    await addMacros(publicationRef, ref, batch)
    await addReferences(publicationRef, ref, batch)
    await batch.commit()
    return ref
  } else {
    const ref = batch.add(glossaryDraftsRef, {
      created_by: uid,
      last_updated_by: uid,
      category_id: undefined,
      terms: [],
      summary: '',
      detail: '',
      created_by_user_at: now,
      updated_by_user_at: now,
    })
    await addDefaultMacos(uid, ref, batch)
    await batch.commit()
    return ref
  }
}

export const publishGlossary = async (draft: GlossaryDraft) => {
  const now = Timestamp.now()
  if (draft.publication_id !== undefined) {
    const batch = new Batch()
    const publicationRef = glossaryRef(draft.publication_id)
    batch.update(publicationRef, {
      last_updated_by: draft.last_updated_by,
      terms: draft.terms,
      category_id: draft.category_id,
      summary: draft.summary,
      detail: draft.detail,
      updated_by_user_at: now,
    })
    await replaceMacros(draft.ref, publicationRef, batch)
    await replaceReferences(draft.ref, publicationRef, batch)
    await updateTermsWithGlossaryInfo(
      publicationRef.id,
      draft.category_id,
      draft.terms,
      batch
    )
    await batch.delete(draft.ref)
    await batch.commit()
    return publicationRef
  } else {
    const batch = new Batch()
    const publicationRef = batch.add(glossariesRef, {
      created_by: draft.created_by,
      last_updated_by: draft.last_updated_by,
      terms: draft.terms,
      category_id: draft.category_id,
      summary: draft.summary,
      detail: draft.detail,
      created_by_user_at: now,
      updated_by_user_at: now,
    })

    await addMacros(draft.ref, publicationRef, batch)
    await addReferences(draft.ref, publicationRef, batch)
    await updateTermsWithGlossaryInfo(
      publicationRef.id,
      draft.category_id,
      draft.terms,
      batch
    )
    await batch.delete(draft.ref)
    await batch.commit()
    return publicationRef
  }
}

export const deleteGlossary = async (glossaryRef: DocRef<Glossary>) => {
  const batch = new Batch()
  await batch.delete(glossaryRef)
  await deleteWithGlossaryInfo(glossaryRef.id, batch)
  await batch.commit()
}

export const updateTermsWithGlossaryInfo = async (
  glossaryId: Glossary['id'],
  categoryId: Glossary['category_id'],
  terms: Term[],
  batch: Batch
) => {
  const ref = termsWithGlossaryInfoRef('ja') // 現状は日本語のみなのでjaから取得
  const snapshot = await getDoc(ref)
  const savedCurrentTermsWithGlossaryInfo = snapshot.data()
  let termsWithGlossaryInfo = savedCurrentTermsWithGlossaryInfo
    ? savedCurrentTermsWithGlossaryInfo.data
    : []
  termsWithGlossaryInfo = termsWithGlossaryInfo.filter(
    (termWithGlossaryInfo) => termWithGlossaryInfo.glossary_id !== glossaryId
  )
  termsWithGlossaryInfo.push(
    ...terms.map((term) => {
      return {
        category_id: categoryId,
        glossary_id: glossaryId,
        display: term.display,
        reading: term.reading,
      }
    })
  )
  termsWithGlossaryInfo = termsWithGlossaryInfo.sort((a, b) =>
    a.reading.toLocaleUpperCase() < b.reading.toLocaleUpperCase() ? -1 : 1
  )
  snapshot.exists()
    ? batch.update(ref, { data: termsWithGlossaryInfo })
    : batch.create(ref, { data: termsWithGlossaryInfo })
}

export const deleteWithGlossaryInfo = async (
  glossaryId: Glossary['id'],
  batch: Batch
) => {
  const ref = termsWithGlossaryInfoRef('ja') // 現状は日本語のみなのでjaから取得
  const snapshot = await getDoc(ref)
  const savedCurrentTermsWithGlossaryInfo = snapshot.data()
  let termsWithGlossaryInfo = savedCurrentTermsWithGlossaryInfo
    ? savedCurrentTermsWithGlossaryInfo.data
    : []
  termsWithGlossaryInfo = termsWithGlossaryInfo.filter(
    (termWithGlossaryInfo) => termWithGlossaryInfo.glossary_id !== glossaryId
  )
  batch.update(ref, { data: termsWithGlossaryInfo })
}
