import { useRouter } from 'next/router'
import { Button } from 'react-bootstrap'
import { Glossary } from '../../firebase'
import { useAuthState } from '../../firebase/hooks'
import { useCurrentUserRoles } from '../../hooks'
import { createGlossaryDraft, deleteGlossary } from '../../models/glossary'
import { Designed } from '../../types'
import { usePopupMessage } from '../messenger'

type Props = {
  glossary: Glossary
}

export const GlossaryActionLinks = ({
  glossary,
  ...otherProps
}: Designed<Props>) => {
  const { hasRole } = useCurrentUserRoles()
  const { setPopupMessage } = usePopupMessage()
  const router = useRouter()
  const { user } = useAuthState()
  if (hasRole('mathpedia_admin') !== true) return <></>
  return (
    <div {...otherProps}>
      <div className="gap-2 d-flex">
        <Button
          variant="link"
          className="text-success small cursor-pointer"
          onClick={async () => {
            if (!user) throw new Error('This request need to authentication.')
            const draftRef = await createGlossaryDraft(user.uid, glossary.ref)
            await router.push(`/glossary_drafts/${draftRef.id}/edit`)
          }}
        >
          編集
        </Button>
        <Button
          variant="link"
          className="text-danger small cursor-pointer"
          onClick={async () => {
            const confirmed = window.confirm('本当に削除しますか。')
            if (confirmed) {
              await deleteGlossary(glossary.ref)
              setPopupMessage('用語の解説を削除しました。', 'danger')
              await router.push('/terms')
            }
          }}
        >
          削除
        </Button>
      </div>
    </div>
  )
}
