import Link from 'next/link'
import { ESGlossary } from '../../elastic_search'
import { Glossary } from '../../firebase'
import { Designed } from '../../types'

type Props = {
  glossary: Glossary | ESGlossary
}

export const GlossaryTitleLink = ({
  glossary,
  ...containerProps
}: Designed<Props>) => (
  <Link href={`/glossaries/${glossary.id}`} passHref>
    <a {...containerProps}>{glossary.terms[0]?.display}</a>
  </Link>
)
